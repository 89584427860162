import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServiceMedia from "../components/ServiceMedia/ServiceMedia.js"
import { authors } from "../../authors.js"
import { Activity } from "react-feather"
import readingTime from "@danieldietrich/reading-time"
import { InlineShareButtons } from "sharethis-reactjs"

import styles from "./article.module.css"
import StartAProject from "../components/StartAProject/StartAProject"

export default ({ data }) => {
  const article = data.markdownRemark
  const articleDetails = article.frontmatter
  const getReadTime = text => {
    const { minutes } = readingTime(text)
    return `${minutes} min read`
  }
  return (
    <Layout>
      <SEO title={articleDetails.title} image={articleDetails.image} />

      <div className={styles.introDetails1} />
      <div className={styles.introDetails2} />
      <div className={styles.introDetails3} />

      <div className={styles.container}>
        <div className={styles.introContainer}>
          <div className={styles.introContent}>
            <Link to="/blog" className={styles.tagBar}>
              <div>
                <Activity />
                PULSE{" "}
              </div>
              <div>{articleDetails.tags}</div>
            </Link>
            <h1 className={styles.staticTitle}>{articleDetails.title}</h1>
            <div className={styles.author}>
              <img
                className={styles.authorImg}
                src={authors[`${articleDetails.author}`].imgPath}
              />
              <div className={styles.authorDetails}>
                <div>
                  By {authors[`${articleDetails.author}`].name} -{" "}
                  {authors[`${articleDetails.author}`].description}
                </div>
                <div>{getReadTime(article.html)}</div>
              </div>
            </div>
          </div>
          <ServiceMedia
            image={articleDetails.image}
            video={articleDetails.video}
            thumbnail={articleDetails.thumbnail}
          />
        </div>
        <div
          className={styles.mainBody}
          dangerouslySetInnerHTML={{ __html: article.html }}
        />
        <a
          className={styles.articleLink}
          href={articleDetails.link}
          target="_blank"
        >
          <img src={articleDetails.linkImage} />
          <div>
            <h3>{articleDetails.linkTitle}</h3>
            <p>{articleDetails.linkText}</p>
          </div>
        </a>
        <InlineShareButtons
          config={{
            alignment: "left", // alignment of buttons (left, center, right)
            color: "social", // set the color of buttons (social, white)
            enabled: true, // show/hide buttons (true, false)
            font_size: 16, // font size for the buttons
            labels: "cta", // button labels (cta, counts, null)
            language: "en", // which language to use (see LANGUAGES)
            networks: [
              // which networks to include (see SHARING NETWORKS)
              "whatsapp",
              "linkedin",
              "messenger",
              "facebook",
              "twitter",
            ],
            padding: 12, // padding within buttons (INTEGER)
            radius: 4, // the corner radius on each button (INTEGER)
            show_total: false,
            size: 40, // the size of each button (INTEGER)
          }}
        />
      </div>
      <StartAProject />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        posttype
        author
        tags
        image
        link
        linkImage
        linkTitle
        linkText
      }
    }
  }
`
