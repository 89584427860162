const authors = {
  tim: {
    name: "Tim Stephens",
    description: "Front-end Developer",
    imgPath: "/author-images/tim-stephens.png",
  },
  siebe: {
    name: "Siebe Van Dijck",
    description: "Founder & CEO",
    imgPath: "/author-images/siebe-van-dijck.png",
  },
}

export { authors }
